import { combineReducers, createStore, compose } from 'redux';
import { dictionaryReducer } from './dictionary/reducer';
import { notificationsReducer } from './notifications/reducer';
import { sidebarReducer } from './sidebar/reducer';
import { socketReducer } from './socket/reducer';
import { userReducer } from './users/reducer';
import Types from './users/types';

const reducers = combineReducers({
  user: userReducer,
  dictionary: dictionaryReducer,
  notifications: notificationsReducer,
  sidebar: sidebarReducer,
  socket: socketReducer,
})

export const action = (type, payload) => {
  return {
    type,
    payload
  }
}

const rootReducer= (state, action) => {
  if (action.type === Types.RESET_STORE) {
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

const composeEnhancers = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers()
);

export default store;