import { message } from "antd";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useService } from "../../../context/services.context";
import Spinner from "../../spinner/Spinner"

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AmazonConfirm = () => {
  const { platformsService } = useService()
  let query = useQuery()
  const history = useHistory()

  const confirmConnection = async (id, code) => {
    try {
      await platformsService.confirm({
        request: id,
        secrets: {
          code
        }
      })
      message.success("Successfully connected!")
      setTimeout(() => history.push('/settings'), 1000)
    } catch(e) {
      message.error("Something went wrong!")
      setTimeout(() => history.push('/'), 1000)
    }
  }

  useEffect(() => {
      const code = query.get('spapi_oauth_code')
      const id = query.get('state')

      if (!code && !id) {
        message.error("Query not found!")
        setTimeout(() => history.push('/settings'), 1000)
      }
      confirmConnection(id, code)
  }, [])
  
  return  <Spinner />
}

export default AmazonConfirm