import React, { Suspense, useState, useEffect, useMemo, lazy } from "react";
import { Layout, Menu, Button, Popover, Select } from "antd";
import Spinner from "../spinner/Spinner";
import "./Main.scss";
import { Route, Switch, Redirect, useHistory, Link } from "react-router-dom";
import Logo from "../icons/Logo";
import LogoMobile from "../icons/LogoMobile";
import { useSelector } from "react-redux";
import ProductsIcon from "../icons/MenuIcons/ProductsIcon";
import SalesIcon from "../icons/MenuIcons/SalesIcon";
import AnalyticsIcon from "../icons/MenuIcons/AnalyticsIcon";
import ImportIcon from "../icons/MenuIcons/ImportIcon";
import SettingsIcon from "../icons/MenuIcons/SettingsIcon";
import PlansIcon from "../icons/MenuIcons/PlansIcon";
import UsersIcon from "../icons/MenuIcons/UsersIcon";
import HowToUseIcon from "../icons/MenuIcons/HowToUseIcon";
import { userTokenKey } from "../../api/apiInitializator";
import { useTranslation } from "react-i18next";
import BrandsIcon from "../icons/MenuIcons/BrandsIcon";
import OnboardingPreview from "../onboarding/OnboardingPreview";

const Suppliers = lazy(() => import('../Suppliers/Suppliers'))
const Brands = lazy(() => import('../brands/Brands'))
const RepricingPage = lazy(() => import('../repricing/RepricingPage'))
const RepricingPageById = lazy(() => import('../repricing/RepricingPageById'))
const HowToUsePage = lazy(() => import('../how-to-use/how-to-use'))
const BillingShowPlan = lazy(() => import('../billingPlans/billingShowPlan'))
const BillingPlans = lazy(() => import('../billingPlans/billingPlans'))
const Notifications = lazy(() => import('../notifications/Notifications'))
const Analytics = lazy(() => import('../analytics/Analytics'))
const Import = lazy(() => import('../import/Import'))
const Reports = lazy(() => import('../reports/Reports'))
const Users = lazy(() => import('../users/Users'))
const Settings = lazy(() => import('../settings/Settings'))
const Sales = lazy(() => import('../sales/Sales'))
const Product = lazy(() => import('../product/Product'))
const TableElement = lazy(() => import('./Table'))
const RecomendedPricesPage = lazy(() => import('../recomendedPricesPage/RecomendedPrices'))


const { Header, Content, Sider } = Layout;

const Main = () => {
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'

  const { me } = useSelector(state => state.user)

  const titles = {
    'repricing': t('menu.repricing'),
    'products': t('menu.products'),
    'main': t('main.title'),
    'sales': t('menu.sales'),
    'brands': t('menu.brands'),
    'analytics': t('menu.analytics'),
    'importexport': t('menu.import/Export'),
    'settings': t('menu.settings'),
    'users': t('menu.users'),
    'plans': t('menu.plans'),
    'payment': t('menu.payment'),
    'how-to-use': t('menu.howUse'),
    'suppliers': t('menu.suppliers'),
    'recomendedPrice': t('menu.recomendedPrice'),
  }

  const [activeMenuItem, setActiveMenuItem] = useState(history.location.pathname.split('/')[1]);
  const [allGroups, setAllGroups] = useState([]);
  const [commerce, setCommerce] = useState("amazon");
  const [dataProd, setDataProd] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [title, setTitle] = useState(titles[history.location.pathname.split('/')[1]])

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed)
  }


  const onLogout = () => {
    localStorage.clear();
    window.location.href = "https://pricex.ai";
  };


  const resize = (e) => {
    if (e.target.innerWidth <= 1340 && !collapsed) {
      setCollapsed(true)
    }
  }

  const mobileClick = (e) => {
    if (!collapsed && window.innerWidth >= 1340) {
      return
    }
    setCollapsed(true)
  }

  const onMenuClick = (e) => {
    setActiveMenuItem(e.key)
    if (window.innerWidth <= 1340 && !collapsed) {
      setCollapsed(true)
    }
  }

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    document.querySelector('html').lang = language;
  }

  const isDropshipping = useMemo(() => {
    if (!me) {
      return false
    }
    return me.business === 'dropshipping'
  }, [me])

  useEffect(() => {
    setTitle(titles[history.location.pathname.split('/')[1]])
    window.addEventListener("resize", resize)
    if (window.innerWidth <= 1340 && !collapsed) {
      setCollapsed(true)
    }
    document.querySelector('html').lang = selectedLanguage
    return  () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  useEffect(() => {
    if (titles[history.location.pathname.split('/')[1]] === title) {
      return
    }
    setTitle(titles[history.location.pathname.split('/')[1]])
  }, [t])

  const getMenu = useMemo(() => {
    if (isDropshipping) {
      return <Menu
      defaultSelectedKeys={[history.location.pathname.split('/')[1] || 'products']}
      mode="inline"
      onClick={onMenuClick}
      style={{
        background: "#fff",
        padding: "25px 30px",
        borderRight: "none",
      }}
    >
      <Menu.Item
        key="products"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ProductsIcon active={activeMenuItem === 'products'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['products'])
        }}
      >
        <Link to='/products'>{t('menu.products')}</Link>
      </Menu.Item>
      <Menu.Item
        key="suppliers"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ProductsIcon active={activeMenuItem === 'suppliers'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['suppliers'])
        }}
      >
        <Link to='/suppliers'>{t('menu.suppliers')}</Link>
      </Menu.Item>
      <Menu.Item
        key="repricing"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <AnalyticsIcon active={activeMenuItem === 'repricing'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['repricing'])
        }}
      >
        <Link to='/repricing'>{t('menu.repricing')}</Link>
      </Menu.Item>
      <Menu.Item
        key="sales"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <SalesIcon active={activeMenuItem === 'sales'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['sales'])
        }}
      >
        <Link to='/sales'>{t('menu.sales')}</Link>
      </Menu.Item>
      <Menu.Item
        key="brands"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <BrandsIcon active={activeMenuItem === 'brands'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['brands'])
        }}
      >
        <Link to='/brands'>{t('menu.brands')}</Link>
      </Menu.Item>
      <Menu.Item
        key="importexport"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ImportIcon active={activeMenuItem === 'importexport'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['importexport'])
        }}
      >
        <Link to='/import'>{t('menu.import/Export')}</Link>
      </Menu.Item>
      <Menu.Item
        key="settings"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <SettingsIcon active={activeMenuItem === 'settings'} /> 
        </span>}
        onClick={(e) => {
          setTitle(titles['settings'])
        }}
      >
        <Link to='/settings'>{t('menu.settings')}</Link>
      </Menu.Item>
      <Menu.Item
        key="how-to-use"
        className={`how-to-use-item`}
        style={{paddingLeft: '10px'}}
        icon={collapsed && <span role="img" className="anticon anticon-team ant-menu-item-icon">
          <HowToUseIcon active={activeMenuItem === 'how-to-use'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['how-to-use'])
        }}
      >
        {collapsed ? <Link to='/how-to-use'><span className='text-l'>{t('menu.needHelp.title')}</span></Link> : <>
          <div className='flex gap-5'>
            <HowToUseIcon className='flex-shrink-0' active={activeMenuItem === 'how-to-use'} />
            <Link to='/how-to-use'><span className='text-white text-l'>{t('menu.needHelp.title')}</span></Link>
          </div>
          <p className='mt-3 text-white'>{t('menu.needHelp.subtitle')}</p>
          <button className='doc-btn mt-3'>{t('menu.needHelp.doc')}</button>
        </>}
      </Menu.Item>
    </Menu>
    }
    return <Menu
      defaultSelectedKeys={[history.location.pathname.split('/')[1] || 'main']}
      mode="inline"
      onClick={onMenuClick}
      style={{
        background: "#fff",
        padding: "25px 30px",
        borderRight: "none",
      }}
    >
      <Menu.Item
        className='menuItem'
        key="main"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ProductsIcon active={activeMenuItem === 'main'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['main'])
        }}
      >
        <Link to='/main'>{t('menu.products')}</Link>
      </Menu.Item>
      <Menu.Item
        className='menuItem'
        key="recomendedPrice"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ProductsIcon active={activeMenuItem === 'recomendedPrice'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['recomendedPrice'])
        }}
      >
        <Link to='/recomended-prices'>{t('menu.recomendedPrice')}</Link>
      </Menu.Item>
      <Menu.Item
        className='menuItem'
        key="sales"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <SalesIcon active={activeMenuItem === 'sales'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['sales'])
        }}
      >
        <Link to='/sales'>{t('menu.sales')}</Link>
      </Menu.Item>
      <Menu.Item
        className='menuItem'
        key="analytics"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <AnalyticsIcon active={activeMenuItem === 'analytics'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['analytics'])
        }}
      >
        <Link to='/analytics'>{t('menu.analytics')}</Link>
      </Menu.Item>
      <Menu.Item
        key="importexport"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <ImportIcon active={activeMenuItem === 'importexport'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['importexport'])
        }}
      >
        <Link to='/import'>{t('menu.import/Export')}</Link>
      </Menu.Item>
      {me && me.roles.includes("admin") && 
      (<>
        <Menu.Item
          className='menuItem'
          key="plans"
          style={{paddingLeft: '10px'}}
          icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
            <PlansIcon active={activeMenuItem === 'plans'} />
          </span>}
          onClick={(e) => {
            setTitle(titles['plans'])
          }}
        >
          <Link to='/plans'>{t('menu.plans')}</Link>
        </Menu.Item>
        <Menu.Item
          className='menuItem'
          key="users"
          style={{paddingLeft: '10px'}}
          icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
            <UsersIcon active={activeMenuItem === 'users'} />
          </span>}
          onClick={(e) => {
            setTitle(titles['users'])
          }}
        >
          <Link to='/users'>{t('menu.users')}</Link>
        </Menu.Item>
      </>)}
      <Menu.Item
        className='menuItem'
        key="settings"
        style={{paddingLeft: '10px'}}
        icon={<span role="img" className="anticon anticon-team ant-menu-item-icon">
          <SettingsIcon active={activeMenuItem === 'settings'} /> 
        </span>}
        onClick={(e) => {
          setTitle(titles['settings'])
        }}
      >
        <Link to='/settings'>{t('menu.settings')}</Link>
      </Menu.Item>
      <Menu.Item
        key="how-to-use"
        className={`how-to-use-item`}
        style={{paddingLeft: '10px'}}
        icon={collapsed && <span role="img" className="anticon anticon-team ant-menu-item-icon">
          <HowToUseIcon active={activeMenuItem === 'how-to-use'} />
        </span>}
        onClick={(e) => {
          setTitle(titles['how-to-use'])
        }}
      >
        {collapsed ? <Link to='/how-to-use'><span className='text-l'>{t('menu.needHelp.title')}</span></Link> : <>
          <div className='flex gap-5'>
            <HowToUseIcon className='flex-shrink-0' active={activeMenuItem === 'how-to-use'} />
            <Link to='/how-to-use'><span className='text-white text-l'>{t('menu.needHelp.title')}</span></Link>
          </div>
          <p className='mt-3 text-white'>{t('menu.needHelp.subtitle')}</p>
          <button className='doc-btn mt-3'>{t('menu.needHelp.doc')}</button>
        </>}
      </Menu.Item>
    </Menu>
    
  }, [history.location.pathname, collapsed, me, t])

  const publicRoutes = [
    !isDropshipping && <Route history={history} path="/main" key='main'>
      <div
        className="site-layout-background main-page"
        style={{ minHeight: 360 }}
      >
        <TableElement
          data={dataProd}
          commerce={commerce}
          setCommerce={setCommerce}
        />
      </div>
    </Route>,
    !isDropshipping && <Route history={history} path="/recomended-prices" key='recomendedPrice'>
      <div
        className="site-layout-background main-page"
        style={{ minHeight: 360 }}
      >
        <RecomendedPricesPage
          data={dataProd}
          commerce={commerce}
          setCommerce={setCommerce}
        />
      </div>
    </Route>,
    isDropshipping && <Route history={history} path="/products" key='products'>
      <div
        className="site-layout-background main-page"
        style={{ minHeight: 360 }}
      >
        <TableElement
          data={dataProd}
          commerce={commerce}
          setCommerce={setCommerce}
        />
      </div>
    </Route>,
    isDropshipping && <Route history={history} path="/repricing/:id" key='repricing'>
      <RepricingPageById />
    </Route>,
    isDropshipping && <Route history={history} path="/repricing" key='repricing-page'>
      <RepricingPage />
    </Route>,
    isDropshipping && <Route history={history} path="/brands" key='brands'>
        <Brands />
      </Route>,
    <Route history={history} path="/product/:id" key='product'>
      <Product
        allGroups={allGroups}
        setAllGroups={setAllGroups}
        dataProd={dataProd[commerce]}
        productPlatformIs={commerce}
        setDataProd={setDataProd}
        dataProdFull={dataProd}
      />
    </Route>,
    <Route history={history} path="/sales" key='sales'>
      <Sales />
    </Route>,
    <Route history={history} path="/suppliers" key='suppliers'>
      <Suppliers />
    </Route>,
    <Route history={history} path="/analytics" key='analytics'>
      {isDropshipping ? <div
        className="site-layout-background main-page"
        style={{ minHeight: 360 }}
      >
        <TableElement
          data={dataProd}
          commerce={commerce}
          setCommerce={setCommerce}
        />
      </div> : <Analytics />}
    </Route>,
    <Route history={history} path="/reports" key='reports'>
      <Reports />
    </Route>,
    <Route history={history} path="/import" key='importexport'>
      <Import />
    </Route>,
    <Route history={history} path="/settings" key='settings'>
      <Settings />
    </Route>,
    <Route history={history} path="/how-to-use" key='how-to-use'>
      <HowToUsePage />
    </Route>
  ];

  const adminRoutes = [
    <Route history={history} path="/users" key='users'>
      <Users />
    </Route>,
    <Route history={history} path="/plans/:id" key='plan'>
      <BillingShowPlan />
    </Route>,
    <Route history={history} path="/plans" key='plans'>
      <BillingPlans />
    </Route>
  ]

  const logoutUnderUser = async () => {
    window.location.pathname = '/users'
    localStorage.removeItem(userTokenKey)
  }

  return (
    <>
      {history.location.pathname === "/" && (
        <Redirect
          to={{
            pathname: isDropshipping ? "/products" : "/main",
            state: { from: history.location },
          }}
        />
      )}
      {isDropshipping && history.location.pathname === "/main" && <Redirect
        to={{
          pathname: "/products",
          state: { from: history.location },
        }}
      />}
      {me && me.method === '00000000-0000-0000-0000-000000000000' && (
        <Redirect
          to={{
            pathname: "/payment",
            state: { from: history.location },
          }}
        />
      )}
      <Sider 
        style={{ background: "#fff", borderRight: '1px solid #d9d9d9' }} 
        width="270"
        collapsible 
        collapsed={collapsed} 
        onCollapse={onCollapse}
      >
        <Link
          className="logo"
          to='/main'
        >
          {collapsed ? <LogoMobile /> : <Logo />}
        </Link>
        {getMenu}
      </Sider>
      <OnboardingPreview is_show_modal={me.status === "had_no_account" && !me.roles.includes('admin')} />
      {localStorage.getItem(userTokenKey) && <div className='login-by-user-notification'>
          <p>You login by <strong>{me.name}</strong></p>
          <Button danger onClick={logoutUnderUser}>{t('menu.logout')}</Button>
      </div>}
      <Layout className="site-layout" onClick={mobileClick}>
        <Header className="site-layout-background header">
            <div className='main-title flex items-center'>
              <h2>{title}</h2>
            </div>
            <div className="username">
              <Select className='rounded-md' defaultValue={selectedLanguage} onChange={(e) => changeLanguage(e)}>
                <Select.Option value="ru"><i className="flag-icon flag-icon-ru"></i> Русский</Select.Option>
                <Select.Option value="en"><i className="flag-icon flag-icon-us"></i> English</Select.Option>
                <Select.Option value="es"><i className="flag-icon flag-icon-es"></i> Español</Select.Option>
                <Select.Option value="fr"><i className="flag-icon flag-icon-fr"></i> Français</Select.Option>
                <Select.Option value="it"><i className="flag-icon flag-icon-it"></i> Italiano</Select.Option>
              </Select>
              <div className='flex gap-x-5'>
                <Notifications>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.95094 8.9142C6.29342 5.83189 8.89876 3.5 12 3.5C15.1013 3.5 17.7067 5.83189 18.0491 8.9142L18.3009 11.1804L18.3072 11.2363C18.4281 12.2897 18.771 13.3056 19.3133 14.2168L19.3421 14.265L19.9202 15.2284L19.9341 15.2516C20.1845 15.669 20.392 16.0147 20.5268 16.301C20.6629 16.5899 20.7678 16.9025 20.7155 17.2338C20.659 17.5919 20.4747 17.9175 20.1966 18.1502C19.9395 18.3655 19.6175 18.4364 19.2997 18.4683C18.9848 18.5 18.5816 18.5 18.0949 18.5H18.0678H5.93227H5.90519C5.41847 18.5 5.01528 18.5 4.70041 18.4683C4.38261 18.4364 4.06063 18.3655 3.80345 18.1502C3.5254 17.9175 3.34107 17.5919 3.28456 17.2338C3.23229 16.9025 3.33718 16.5899 3.47327 16.301C3.60811 16.0147 3.81556 15.669 4.06599 15.2516L4.07991 15.2284L4.65795 14.265L4.68681 14.2168C5.2291 13.3056 5.572 12.2897 5.69288 11.2363L5.69914 11.1804L5.95094 8.9142ZM12 4.5C9.40831 4.5 7.23103 6.44875 6.94482 9.02463L6.69302 11.2909L6.68636 11.3503C6.54936 12.5442 6.16074 13.6955 5.54615 14.7282L5.51544 14.7795L4.9374 15.7429C4.66952 16.1894 4.48875 16.4918 4.37796 16.7271C4.26593 16.9649 4.26799 17.0504 4.27234 17.0779C4.29118 17.1973 4.35262 17.3058 4.4453 17.3834C4.4667 17.4013 4.53889 17.447 4.80051 17.4734C5.05923 17.4994 5.41159 17.5 5.93227 17.5H18.0678C18.5885 17.5 18.9408 17.4994 19.1996 17.4734C19.4612 17.447 19.5334 17.4013 19.5548 17.3834C19.6475 17.3058 19.7089 17.1973 19.7277 17.0779C19.7321 17.0504 19.7342 16.9649 19.6221 16.7271C19.5113 16.4918 19.3306 16.1894 19.0627 15.7429L18.4846 14.7795L18.4539 14.7282C17.8393 13.6955 17.4507 12.5442 17.3137 11.3503L17.3071 11.2909L17.0553 9.02463C16.769 6.44875 14.5918 4.5 12 4.5Z" fill="#C2CFE0"/>
                    <path d="M9.10222 18.4059C9.27315 19.1501 9.64978 19.8077 10.1737 20.2767C10.6976 20.7458 11.3396 21 12 21C12.6604 21 13.3024 20.7458 13.8263 20.2767C14.3502 19.8077 14.7269 19.1501 14.8978 18.4059" stroke="#C2CFE0" strokeLinecap="round"/>
                  </svg>
                </Notifications>
                <Popover content={<Button onClick={onLogout}>{t('menu.logout')}</Button>}>
                  <div className='flex flex-center username-mail'>
                  {/* <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px', fill: 'inherit'}}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM8.75 4.375C8.75 5.3415 7.96653 6.125 7 6.125C6.0335 6.125 5.25 5.3415 5.25 4.375C5.25 3.4085 6.0335 2.625 7 2.625C7.96653 2.625 8.75 3.4085 8.75 4.375ZM6.99994 7.875C5.23459 7.875 3.71345 8.92062 3.02202 10.4262C3.98478 11.543 5.40982 12.25 6.99998 12.25C8.59014 12.25 10.0152 11.5431 10.9779 10.4263C10.2865 8.92062 8.76531 7.875 6.99994 7.875Z" />
                  </svg> */}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px', fill: 'none'}}>
                    <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#C2CFE0" strokeWidth="1.2" strokeLinecap="round"/>
                    <circle cx="12" cy="8" r="4" stroke="#C2CFE0" strokeWidth="1.2" strokeLinecap="round"/>
                  </svg>
                    <p className='text-gray2 text-xs'>{me && me.name}</p>
                    <svg className='ml-2' width="7" height="4" viewBox="0 0 7 3" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.5 3L0.468911 -9.41288e-08L6.53109 4.35844e-07L3.5 3Z" fill="#C2CFE0"/>
                    </svg>
                  </div>
                </Popover>
              </div>
            </div>
        </Header>
        <Content style={{ background: "#f8f9fa" }}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {me && !me.roles.includes('admin') ? publicRoutes : [...publicRoutes, ...adminRoutes]}
            </Switch>
          </Suspense>
        </Content>
      </Layout>
    </>
  );
};

export default Main;
