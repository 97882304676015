import store from "../../store"
import { setCurrencies, setMarketplacess, setPlatforms, setTags } from "../../store/dictionary/actions"

export class ProductsServiceImpl {
  constructor(productsRepository) {
    this.productsRepository = productsRepository
  }

  async createProduct(product) {
    return this.productsRepository.createProduct(product)
  }

  async editProduct(id, product) {
    return this.productsRepository.editProduct(id, product)
  }

  async deleteProduct(id) {
    return this.productsRepository.deleteProduct(id)
  }

  async updatePredictedPrice(id, price) {
    return this.productsRepository.updatePredictedPrice(id, price)
  }

  async setProductEnable(enabled, id) {
    return this.productsRepository.setProductEnable(enabled, id)
  }

  async getProductsList(requestParams) {
    return await this.productsRepository.getProductsList(requestParams)
  }

  async showProduct(id) {
    return this.productsRepository.showProduct(id)
  }

  async showProductHistory(id, requestParams) {
    return this.productsRepository.showProductHistory(id, requestParams)
  }

  async fetchProduct(id) {
    return this.productsRepository.fetchProduct(id)
  }

  async editProductPrice(id, price) {
    return this.productsRepository.editProductPrice(id, price)
  }

  async editProductSku(id, sku) {
    return this.productsRepository.editProductSku(id, sku)
  }

  async describeProduct(id, obj) {
    return this.productsRepository.describeProduct(id, obj)
  }

  async editProductTags(id, tags) {
    return this.productsRepository.editProductTags(id, tags)
  }

  async addProductCompetitor(id, competitor) {
    return this.productsRepository.addProductCompetitor(id, competitor)
  }

  async deleteProductCompetitor(productId, competitorId) {
    return this.productsRepository.deleteProductCompetitor(productId, competitorId)
  }

  async getSuppliers(id) {
    return this.productsRepository.getSuppliers(id)
  }

  async addSupplier(id, obj) {
    return this.productsRepository.addSupplier(id, obj)
  }

  async removeSupplier(productId, supplierId) {
    return this.productsRepository.removeSupplier(productId, supplierId)
  }

  async preferSupplier(productId, supplierId) {
    return this.productsRepository.preferSupplier(productId, supplierId)
  }
}