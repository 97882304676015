import { transformRequestOptions } from "../../utils/apiUtils";
import { statisticInstance } from "../apiInitializator";
import { DateTime } from "luxon";

export class StatisticRepositoryImpl {

  async get(from) {
    const to = DateTime.utc().minus({days: 1}).toFormat("dd-MM-yyyy")
    const requestParams = {
      from: from || to, 
      to
    }
    return statisticInstance.get('', {
      params: requestParams,
      paramsSerializer: params => transformRequestOptions(params)
    })
  }
}