import Types from './types';

const init = {
  active: null,
  onSubmitRedux: null
};

export function sidebarReducer(state = init, action) {
  switch (action.type) {
    case Types.SET_SIDEBAR: 
      return { ...state, active: action.payload.sidebar, onSubmitRedux: action.payload.onSubmitRedux || null};
    default:
      return state;
  }
}