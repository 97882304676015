import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useService } from "../../../context/services.context";
import getAmazonPlatform from "../../../utils/getAmazonPlatform";
import EnterForm from "../../login/EnterForm";
import LoginContainer from "../../login/LoginContainer";
import Spinner from "../../spinner/Spinner"

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AmazonConnect = () => {
  const { platformsService, dictionaryService, authService} = useService()

  const [isAuth, setAuth] = useState(null)
  const auth = localStorage.getItem("token")
  const history = useHistory()
  let query = useQuery()

  const authorization = async () => {
    if (auth) {
      try {
        await dictionaryService.getCurrencies()
        setAuth(true)
      } catch(e) {
        localStorage.clear()
        setAuth(false)
      }
    }
  }

  const onFinish = async (values) => {
    try {
      const token = await authService.getAccessToken(values)
      await authService.signIn(token)
    } catch(e) {

    }
  };

  useEffect(() => {
    authorization()
  }, [])

  const connectHandle = async (platform, obj) => {
    try {
      const data = await platformsService.connect(platform, obj)
      window.location.href = data.location
    } catch(e) {
      history.push('/main')
    }
  }

  useEffect(() => {
    if (isAuth) {
      const url = query.get('amazon_callback_uri')
      const state = query.get('amazon_state')
      const seller = query.get('selling_partner_id')

      if (!url && !state && !seller) {
        message.error("Query not found!")
        setTimeout(() => history.push('/settings'), 1000)
      }
      connectHandle(getAmazonPlatform(url), {
        url,
        state,
        seller
      })
    }
  }, [isAuth])
  
  return  isAuth === null || isAuth ? 
  <Spinner /> : <LoginContainer>
    <EnterForm onFinish={onFinish} />
  </LoginContainer>
}

export default AmazonConnect