import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./Login.scss";

const EnterForm = ({
  onFinish
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <>
      <h3>Welcome</h3>
      <Form
        form={form}
        name="basic"
        //layout="inline"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "100%" }}
      >
        <Form.Item
          name="username"
          style={{ justifyContent: "center" }}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          style={{ justifyContent: "center" }}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item style={{ justifyContent: "center", textAlign: "right" }}>
          <a className="login-form-forgot" href="/#">
            Forgot password?
          </a>
        </Form.Item>
        <Form.Item style={{ justifyContent: "center" }} shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                background: "#315EFB",
                color: "#fff",
              }}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Log in
            </Button>
          )}
        </Form.Item>
        <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
          <span className="or">Or</span>
        </Form.Item>

        <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
          <p>Have no account yet?</p>
          <Button
            type="primary"
            className="reg_btn"
            onClick={() => history.push("auth/registration")}
          >
            Registration
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EnterForm;
