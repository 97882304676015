import React, { useEffect, useState } from 'react'
import CustomModal from '../main/CustomModal'
import { Button } from 'antd'
import { Link } from "react-router-dom";
const OnboardingPreview = ({
  is_show_modal
}) => {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (is_show_modal) {
      setModal(true)
    }
  }, [])

  return (
    <CustomModal
      showModal={modal}
      setShowModal={setModal}
      title="Hi, Dear User!"
      footer={false}
      destroyOnClose
      maskClosable={false}
      width="none"
      className='w-160'
    >
      <p className='mb-2'>You registered at PriceX.ai, but haven't connected your Amazon account to our service yet. Only after that you will have availability to use 100% functionality of PriceX.ai.</p>
      <p className='mb-2'><strong>The connection is fast</strong> and simple)) You need to:</p>
      <ul>
        <li>Go to "Settings" at the left menu.</li>
        <li>Press "Connect" near the market where you are selling.</li>
        <li>You will be redirected to Amazon Seller Center where you will need to choose all checkboxes and press "Confirm" button.</li>
        <li>That's all))</li>
      </ul>
      <p>Also you can watch <a href='https://www.youtube.com/watch?v=gVkD7gveGKM' target='_blank'>this YouTube video</a> to understand how to do it.</p>
      <div className='my-3'>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/gVkD7gveGKM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <Link to='/settings'>
        <Button type='primary' onClick={() => setModal(false)}>GO TO SETTINGS</Button>
      </Link>
    </CustomModal>
  )
}

export default OnboardingPreview