import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import translationEN from "./en.json";
import translationRU from "./ru.json";
import translationES from "./es.json";
import translationFR from "./fr.json";
import translationIT from "./it.json";


const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

const options = {
  order: ["queryString", "cookie"],
  cache: ["cookie"],
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    resources,
    fallbackLng: localStorage.getItem("i18nextLng") || "en",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.changeLanguage(localStorage.getItem("i18nextLng"));
document.querySelector('html').lang = localStorage.getItem("i18nextLng");

export default i18n;