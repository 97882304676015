import { action } from "..";
import Types from "./types";

const setSocket = (values) => {
  return action(Types.SET_SOCKET, {values});
}

export {
  setSocket
}
