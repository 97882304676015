const SalesIcon = ({active}) => {
  return active ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.87868 0.87868C0 1.75736 0 3.17157 0 6V18C0 20.8284 0 22.2426 0.87868 23.1213C1.75736 24 3.17157 24 6 24H18C20.8284 24 22.2426 24 23.1213 23.1213C24 22.2426 24 20.8284 24 18V6C24 3.17157 24 1.75736 23.1213 0.87868C22.2426 0 20.8284 0 18 0H6C3.17157 0 1.75736 0 0.87868 0.87868ZM16.8003 6.99998C17.3526 6.99998 17.8003 7.44769 17.8003 7.99998V18.6666C17.8003 19.2189 17.3526 19.6666 16.8003 19.6666C16.248 19.6666 15.8003 19.2189 15.8003 18.6666V7.99998C15.8003 7.44769 16.248 6.99998 16.8003 6.99998ZM8.2002 10.6666C8.2002 10.1144 7.75248 9.66665 7.2002 9.66665C6.64791 9.66665 6.2002 10.1144 6.2002 10.6666V18.6666C6.2002 19.2189 6.64791 19.6666 7.2002 19.6666C7.75248 19.6666 8.2002 19.2189 8.2002 18.6666V10.6666ZM13 13.3333C13 12.781 12.5523 12.3333 12 12.3333C11.4477 12.3333 11 12.781 11 13.3333V18.6666C11 19.2189 11.4477 19.6666 12 19.6666C12.5523 19.6666 13 19.2189 13 18.6666V13.3333Z" fill="#00CCFF"/>
  </svg> : <svg style={{fill: 'none'}} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.6665 10L7.6665 19" stroke="#C2CFE0" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13 13V19" stroke="#C2CFE0" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3335 7V19" stroke="#C2CFE0" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="1" y="1" width="24" height="24" rx="2" stroke="#C2CFE0"/>
  </svg>
}

export default SalesIcon