import { action } from "..";
import Types from "./types";

const setSidebar= ({sidebar, onSubmitRedux}) => {
  return action(Types.SET_SIDEBAR, {
    sidebar,
    onSubmitRedux
  });
}

export {
  setSidebar
}
