export class ReportsServiceImpl {
  constructor(reportsRepository) {
    this.reportsRepository = reportsRepository
  }

  async getCompetitorsReports(requestParams) {
    return this.reportsRepository.getCompetitorsReports(requestParams)
  }

  async downloadCompetitorsReports(requestParams) {
    return this.reportsRepository.downloadCompetitorsReports(requestParams)
  }
}