import { dictionaryInstance } from "../apiInitializator";

export class DictionaryRepositoryImpl {

  async getPlatforms() {
    return dictionaryInstance.get('platforms')
  }

  async getCountries() {
    return dictionaryInstance.get('countries')
  }

  async getCurrencies() {
    return dictionaryInstance.get('currencies')
  }

  async getTags() {
    return dictionaryInstance.get('tags')
  }

  async getMarketplaces() {
    return dictionaryInstance.get('marketplaces')
  }
}