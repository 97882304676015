import {
  AuthRepositoryImpl,
  DictionaryRepositoryImpl,
  NotificationsRepositoryImpl,
  ProductsRepositoryImpl,
  ReportsRepositoryImpl,
  StatisticRepositoryImpl,
  UsersRepositoryImpl,
  PlatformsRepositoryImpl,
  BrandsRepositoryImpl,
  PaymentRepositoryImpl,
  RepricingRepositoryImpl
} from './repositories'

import {
  AuthServiceImpl,
  DictionaryServiceImpl,
  NotificationsServiceImpl,
  ProductsServiceImpl,
  ReportsServiceImpl,
  StatisticServiceImpl,
  UsersServiceImpl,
  PlatformsServiceImpl,
  BrandsServiceImpl,
  PaymentServiceImpl,
  RepricingServiceImpl
} from './services'

// import * as bepaid from 'https://js.bepaid.by/cse/v.1.0.0/begateway-cse.min.js'

function initializeServices() {
  const authRepository = new AuthRepositoryImpl();
  const dictionaryRepository = new DictionaryRepositoryImpl();
  const notificationsRepository = new NotificationsRepositoryImpl();
  const productsRepository = new ProductsRepositoryImpl();
  const reportsRepository = new ReportsRepositoryImpl();
  const statisticRepository = new StatisticRepositoryImpl();
  const usersRepository = new UsersRepositoryImpl();
  const platformsRepository = new PlatformsRepositoryImpl();
  const brandsRepository = new BrandsRepositoryImpl();
  const paymentRepository = new PaymentRepositoryImpl();
  const repricingRepository = new RepricingRepositoryImpl();

  const authService = new AuthServiceImpl(authRepository);
  const dictionaryService = new DictionaryServiceImpl(dictionaryRepository);
  const notificationsService = new NotificationsServiceImpl(notificationsRepository);
  const productsService = new ProductsServiceImpl(productsRepository);
  const reportsService = new ReportsServiceImpl(reportsRepository);
  const statisticService = new StatisticServiceImpl(statisticRepository);
  const usersService = new UsersServiceImpl(usersRepository);
  const platformsService = new PlatformsServiceImpl(platformsRepository);
  const brandsService = new BrandsServiceImpl(brandsRepository);
  const paymentService = new PaymentServiceImpl(paymentRepository);
  const repricingService = new RepricingServiceImpl(repricingRepository);

  return {
    authService,
    dictionaryService,
    notificationsService,
    productsService,
    reportsService,
    statisticService,
    usersService,
    platformsService,
    brandsService,
    paymentService,
    repricingService
  }
}

export {
  initializeServices
};
