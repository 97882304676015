import { authInstance, emptyInstance } from "../apiInitializator";

export class AuthRepositoryImpl {

  async getAccessToken({username, password}) {
    return authInstance.post(
      '',
      {
        username,
        password
      },
    )
  }

  async getAccessTokenUnderUser(id) {
    return authInstance.post(id)
  }

  async getUserInfo() {
    return emptyInstance.get('me')
  }

}