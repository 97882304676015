import React from "react";
import { Modal} from "antd";

const CustomModal = (props) => {
  const {
    children,
    title,
    okText = "Submit",
    cancelText = "Cancel",
    showModal,
    setShowModal,
    onSubmit,
    centered = false,
    footer = true,
    width,
    closable = true,
    ...restProps
  } = props;

  const hideModal = () => {
    setShowModal(!showModal);
  };

  const Submit = (e) => {
    onSubmit(e);
    // hideModal();
  };

  return !footer ? (
    <>
      <Modal
        title={title}
        visible={showModal}
        onOk={Submit}
        onCancel={hideModal}
        okText={okText}
        cancelText={cancelText}
        footer={null}
        centered={centered}
        width={width}
        closable={closable}
        {...restProps}
      >
        {children}
      </Modal>
    </>
  ) : (
    <>
      <Modal
        title={title}
        visible={showModal}
        onOk={Submit}
        onCancel={hideModal}
        okText={okText}
        cancelText={cancelText}
        centered={centered}
        width={width}
        closable={closable}
        {...restProps}
      >
        {children}
      </Modal>
    </>
  );
};

export default CustomModal;
