import React, { useRef, useState } from 'react'
import Cards from 'react-credit-cards';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import 'react-credit-cards/es/styles-compiled.css';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import './Payment.scss';
import { useService } from "../../context/services.context";
import {State}  from 'country-state-city';
import { useTranslation } from 'react-i18next';

const PaymentPage = ({
  className,
  buttonText,
  isShowPaymentText = false,
  onSubmit,
  children,
  width = 290
}) => {
  const {t} = useTranslation()
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();

  const { countries } = useSelector(state => state.dictionary)
  const { onSubmitRedux } = useSelector(state => state.sidebar)
  const { me } = useSelector(state => state.user)


  const { paymentService } = useService()

  const [state, setState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    expiry_month_h: '',
    expiry_year_h: '',
    country: undefined,
    _state: undefined
  })

  const begateway = useRef(new window.BeGatewayCSE(process.env.REACT_APP_BEPAID_PUBLIC_KEY))

  const handleInputFocus = (e) => {
    setState(prev => ({...prev, focus: e.target.name }));
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prev => ({...prev, [name]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const encryptedInputs = [
      'encrypted_number',
      'encrypted_exp_year',
      'encrypted_exp_month',
      'encrypted_verification_value',
      'encrypted_holder'
    ]

    await begateway.current.encrypt("begateway-encrypted-form")

    const { country, _state ,city, zip_code, line, firstName, lastName} = state;

    const endpoinJson = {
      address: {
        full_name: {
          first: firstName,
          last: lastName
        },
        country,
        state: _state,
        city,
        zip_code,
        line
      },
      card: {
        number: e.target.encrypted_number.value,
        holder: e.target.encrypted_holder.value,
        exp_month: e.target.encrypted_exp_month.value,
        exp_year: e.target.encrypted_exp_year.value,
        code: e.target.encrypted_verification_value.value
      },
      return: `${process.env.REACT_APP_BASE_URL}${window.location.pathname}?status=processing`
    }
    await paymentService.addPaymentMethod(endpoinJson)
    onSubmit && onSubmit()
    onSubmitRedux && onSubmitRedux()
    encryptedInputs.forEach(inp => {
      document.querySelectorAll(`input[type=hidden][name=${inp}`).forEach(element => {element.remove()})
    })
  }

  const onExpiryChange = (e) => {
    if (e.target.value.length === 7) {
      const arr = e.target.value.split(' / ')
      setState(prev => ({...prev, expiry_month_h: arr[0], expiry_year_h: +`20${arr[1]}`}))
    }
  }

  return <div className={`${className} flex flex-col`}>
    <form 
      onSubmit={handleSubmit} 
      id="begateway-encrypted-form" 
      className='cards-form flex flex-col items-center space-y-4'
      onChange={handleInputChange}
    >
      <div className='flex space-x-5 w-full justify-center'>
        <div style={{width: `${width}px`}} className='flex flex-col space-y-3'>
          <input
            required 
            className='card-input'
            name="firstName"
            id="firstName" 
            type='text'
            placeholder={t('payment.firstName')}
          />
          <input
            required 
            className='card-input'
            name="lastName"
            id="lastName"
            type='text'
            placeholder={t('payment.lastName')}
          />
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            required
            placeholder={t('payment.country')}
            name="country"
            id="country"
            onChange={(e) => setState(prev => ({...prev, country: e }))}
          >
            {countries && countries.map((item, idx) => (<Select.Option 
              key={idx} 
              value={item.key}
            >
              {item.value}
            </Select.Option>))}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            required
            placeholder={t('payment.state')}
            name="_state"
            id="_state"
            onChange={(e) => setState(prev => ({...prev, _state: e }))}
          >
            {State.getStatesOfCountry(state.country).map((item, idx) => (<Select.Option 
              key={idx} 
              value={item.isoCode}
            >
              {item.name}
            </Select.Option>))}
          </Select>
          <input
            required 
            className='card-input'
            name="city"
            id="city"
            type='text'
            placeholder={t('payment.city')}
          />
          <input
            required 
            className='card-input'
            name="zip_code"
            id="zip_code" 
            placeholder={t('payment.zip_code')}
            {...(state.country === 'US' || state.country === 'CA') && {pattern: state.country === 'US' ? "(^\\d{5}$)|(^\\d{5}-\\d{4}$)" : "(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$)"}}
          />
          <input
            required 
            className='card-input'
            name="line"
            id="line" 
            type='tel'
            placeholder={t('payment.address')}
          />
          {isShowPaymentText && <h3 className='text-center text-xl my-3'>{t('payment.addMethod')}</h3>} 
          <div className='self-center'>
            <Cards
              number={state.number}
              name={state.name}
              expiry={state.expiry.replace(/ /g, '')}
              cvc={state.cvc}
              focused={state.focus}
            />
          </div>
          <p className='text-xs text-dark text-left'>{t('payment.mark')}</p>
          <PaymentInputsWrapper {...wrapperProps}>
            <svg {...getCardImageProps({ images })} onFocus={handleInputFocus} />
            <input 
              {...getCardNumberProps()}
              name="number" 
              onFocus={handleInputFocus}
              required
              placeholder={t('payment.number')}
            />
            <input 
              {...getExpiryDateProps()} 
              name="expiry" 
              onFocus={handleInputFocus} 
              onBlur={onExpiryChange}
              required
            />
            <input 
              {...getCVCProps()} 
              name="cvc"
              data-encrypted-name="encrypted_verification_value"
              onFocus={handleInputFocus}
              required
            />
          </PaymentInputsWrapper>
          <input
            data-encrypted-name="encrypted_exp_month"
            type='hidden'
            autoComplete="off"
            name="expiry_month_h"
            value={state.expiry_month_h}
          />
          <input
            data-encrypted-name="encrypted_exp_year"
            type='hidden'
            autoComplete="off"
            name="expiry_year_h"
            value={state.expiry_year_h}
          />
          <input 
            type="hidden" 
            autoComplete="off"
            data-encrypted-name="encrypted_number" 
            value={state.number.replace(/ /g, '')}
            />
          <input 
            className='card-input'
            data-encrypted-name="encrypted_holder"
            name="name" 
            type='text'
            required
            placeholder={t('payment.holder')}
            onFocus={handleInputFocus}
          />
          {children && children}
        </div>
      </div>
      <button className='add-card-button w-full py-2' htmlType='submit'>
        {buttonText || t('payment.addBtn')}
      </button>
    </form>
  </div>
}

export default PaymentPage