export class PlatformsServiceImpl {
  constructor(platformsRepository) {
    this.platformsRepository = platformsRepository
  }

  async get() {
    return this.platformsRepository.get()
  }

  async refresh(platform) {
    return this.platformsRepository.refresh(platform)
  }

  async connect(connectObj) {
    return this.platformsRepository.connect(connectObj)
  }

  async confirm(confirmObj) {
    return this.platformsRepository.confirm(confirmObj)
  }
}