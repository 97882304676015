import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { useEffect, useState } from "react";

const Registration = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const history = useHistory();

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = (values) => {
    //console.log("Success:", values);
    if (values.username === "admin" || values.password === "admin") {
      localStorage.setItem("login", values.username);
      localStorage.setItem("token", 123123);
      history.push("/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  return (
    <>
      <h3>Welcome</h3>
      <Form
        form={form}
        name="basic"
        //layout="inline"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "100%" }}
      >
        <Form.Item
          name="username"
          style={{ justifyContent: "center" }}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          style={{ justifyContent: "center" }}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          style={{ justifyContent: "center" }}
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Confirm password"
          />
        </Form.Item>
        <Form.Item style={{ justifyContent: "center" }} shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                background: "#315EFB",
                color: "#fff",
              }}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Registration
            </Button>
          )}
        </Form.Item>
        <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
          <span className="or">Or</span>
        </Form.Item>

        <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
          <p>Already have account?</p>
          <Button
            type="primary"
            className="reg_btn"
            onClick={() => history.push("/auth")}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Registration;
