import React, { useEffect, useRef, useState, useMemo} from 'react'
import {CSSTransition} from "react-transition-group";
import { useSelector } from 'react-redux';
import './interactiveSidebar.scss';
import store from '../../store';
import { setSidebar } from '../../store/sidebar/actions';

const InteractiveSideBarWrapper = ({
  children
}) => {
  const { active } = useSelector(state => state.sidebar)

  const [isInteractiveSideBarOpen, setIsInteractiveSideBarOpen] = useState(false)
  const [chosenInteractiveSideBar, setChosenInteractiveSideBar] = useState(null)

  const interactiveSideBarContentRef = useRef(null);

  const ChosenInteractiveSideBarComponent = useMemo(() => {
    if (!chosenInteractiveSideBar) {
      return null
    }
    return children[chosenInteractiveSideBar]
  }, [chosenInteractiveSideBar])

  const closeInteractiveSideBar = async () => {
    store.dispatch(setSidebar({sidebar: null, onSubmitRedux: null}))
  }
  
  useEffect(() => {
    if (active) {
      setIsInteractiveSideBarOpen(true)
      setChosenInteractiveSideBar(active)
      return
    }
    setIsInteractiveSideBarOpen(false)
    setChosenInteractiveSideBar(null)
  }, [active])

  return (
    <CSSTransition
      in={isInteractiveSideBarOpen}
      timeout={150}
      classNames={{
        enterActive: 'interactive-side-bar-enter-active',
        enterDone: 'interactive-side-bar-enter-done',
        exit: 'interactive-side-bar-enter-active',
        exitActive: 'interactive-side-bar-exit-done',
      }}
      //onExited={closeInteractiveSideBar}
      unmountOnExit
      nodeRef={interactiveSideBarContentRef}
    >
      <div className={'interactive-side-bar restrict-content'}>
        <div ref={interactiveSideBarContentRef}>
          <div className={'interactive-background'}></div>
          <div 
            className={'interactive-content'}
          >
            <div className='flex flex-col'>
              <button id='close' className='self-start m-5' onClick={closeInteractiveSideBar}></button>
              {ChosenInteractiveSideBarComponent}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default InteractiveSideBarWrapper
