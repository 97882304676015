import { platformsInstance } from "../apiInitializator";

export class PlatformsRepositoryImpl {

  async get() {
    return platformsInstance.get('')
  }

  async refresh(platform) {
    return platformsInstance.post(`${platform}/refresh`)
  }

  async connect(connectObj) {
    return platformsInstance.post(`connect`, connectObj)
  }

  async confirm(confirmObj) {
    return platformsInstance.post(`confirm`)
  }
}