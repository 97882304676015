import React, {useEffect, useState} from 'react'
import {useService} from '../../context/services.context'
import Spinner from '../spinner/Spinner'
import {useHistory} from "react-router-dom";
import { setSocket } from '../../store/socket/actions';
import store from '../../store';
import {Centrifuge} from 'centrifuge';

// const Centrifuge = require("centrifuge");


const GlobalWrapper = ({children}) => {

const {
  dictionaryService, 
  authService,
  notificationsService,
} = useService()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [me, setMe] = useState()

  useEffect(() => {
    if (me) {
      const centrifuge = new Centrifuge(
        'wss://app.pricex.ai/ws/connection/websocket',
        { token: me.socket_token}
      );

      centrifuge.connect();

      const sub = centrifuge.newSubscription(`personal:user#${me.id}`).on('publication', function (ctx) {
        store.dispatch(setSocket(ctx.data))
      }).subscribe();
    }
  }, [me])

  useEffect(() => {
    const loadDictionary = async () => {
      await dictionaryService.load()
    }

    const loadUser = async () => {
      const user = await authService.getUserInfo()
      setMe(user)
      return user
    }

    const loadNotifications = async () => {
      await notificationsService.load()
    }

    const loadInfo = async () => {
      await Promise.all([
        loadDictionary(),
        loadUser(),
        loadNotifications(),
      ]).catch(() => {}).finally(() => {
        setLoading(false)
      })
      return
    }

    loadInfo()
  }, [])

  return (
    <>{loading ? <Spinner /> : children}</>
  )
}

export default GlobalWrapper
