import { useHistory } from "react-router-dom";
import "./Login.scss";
import { useEffect, useState } from "react";
import EnterForm from "./EnterForm";
import {useService} from '../../context/services.context'

const Enter = () => {
  //const [form] = Form.useForm();
  const {authService} = useService()
  const [, forceUpdate] = useState({});
  const history = useHistory();
 
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async (values) => {
    const token = await authService.getAccessToken(values)
    await authService.signIn(token)
    if (!localStorage.getItem('pastPath')) {
      history.push("/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  return (<EnterForm 
    onFinish={onFinish} 
    onFinishFailed={onFinishFailed} 
  />);
};

export default Enter;
