export class BrandsServiceImpl {
  constructor(brandsRepository) {
    this.brandsRepository = brandsRepository
  }

  async getAccounts() {
    return this.brandsRepository.getAccounts()
  }

  async watchCategory(accountId, category) {
    return this.brandsRepository.watchCategory(accountId, category)
  }

  async unwatchCategory(accountId, category) {
    return this.brandsRepository.unwatchCategory(accountId, category)
  }

  async import(account, discount, tags) {
    return this.brandsRepository.import(account, discount, tags)
  }

  async getCategoriesList(platform) {
    return this.brandsRepository.getCategoriesList(platform)
  }

  async getCategoriesChildrenList(platform, parent) {
    return this.brandsRepository.getCategoriesChildrenList(platform, parent)
  }

  async getBrandsList(account, category, min, max) {
    return this.brandsRepository.getBrandsList(account, category, min, max)
  }
}