import { action } from "..";
import Types from "./types";

const setUser = (user) => {
  return action(Types.SET_USERS, {
    user
  });
}

export {
  setUser,
}
