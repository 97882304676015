export class PaymentServiceImpl {
  constructor(paymentRepository) {
    this.paymentRepository = paymentRepository
  }

  async getPlansList() {
    return this.paymentRepository.getPlansList()
  }

  async getPublicPlansList() {
    return this.paymentRepository.getPublicPlansList()
  }

  async showPlan(id) {
    return this.paymentRepository.showPlan(id)
  }
  
  async createPlan(values) {
    return this.paymentRepository.createPlan(values)
  }

  async setEnablePlan(id, value) {
    return this.paymentRepository.setEnablePlan(id, value)
  }

  async getPaymentMethods() {
    return this.paymentRepository.getPaymentMethods()
  }

  async addPaymentMethod(data) {
    return this.paymentRepository.addPaymentMethod(data)
  }

  async switchPaymentMethod(id) {
    return this.paymentRepository.switchPaymentMethod(id)
  }

  async deletePaymentMethod(id) {
    return this.paymentRepository.deletePaymentMethod(id)
  }

  async addPromo(data) {
    return this.paymentRepository.addPromo(data)
  }

  async deletePromo(id) {
    return this.paymentRepository.deletePromo(id)
  }
}