import { action } from "..";
import Types from "./types";

const setNotifications = (notifications) => {
  return action(Types.SET_NOTIFICATIONS, {
    notifications
  });
}

export {
  setNotifications,
}
