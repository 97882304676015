import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from 'react-redux';
import App from "./App";
import Spinner from "./components/spinner/Spinner";
import { BrowserRouter } from "react-router-dom";
import store from './store'
import GlobalWrapper from "./components/other/GlobalWrapper";
import { ServicesProvider } from "./context/services.context";
import { initializeServices } from "./api/servicesInitializator";
import "./locales/i18n";

const services = initializeServices();

ReactDOM.render(
  <StoreProvider store={store}>
    <ServicesProvider services={services}>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </ServicesProvider>
  </StoreProvider>,
  document.getElementById("root")
);
