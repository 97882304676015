import React, {useCallback} from "react"
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'
import remarkExternalLinks from 'remark-external-links';
import remarkGfm from 'remark-gfm';
import remarkHint from 'remark-hint';
import remarkDirective from 'remark-directive';
import {visit} from 'unist-util-visit'
import {h} from 'hastscript'
import styles from "./MarkdownReplacer.module.scss";
import { Link } from "react-router-dom";

const MarkdownReplacer = ({
  className,
  markdown
}) => {
  const cn = classnames(
    className,
    'markdown'
  )

  const remarkInitDirectivePlugin = useCallback(() => {
    return (tree) => {
      visit(tree, (node) => {
        if (
          node.type === 'textDirective' ||
          node.type === 'leafDirective' ||
          node.type === 'containerDirective'
        ) {
          const data = node.data || (node.data = {})
          const hast = h(node.name, node.attributes)
  
          data.hName = hast.tagName
          data.hProperties = hast.properties
        }
      })
    }
  }, [])

  return (
    <ReactMarkdown
      className={cn}
      children={markdown}
      remarkPlugins={[
        remarkGfm,
        remarkHint,
        remarkExternalLinks,
        remarkDirective,
        remarkInitDirectivePlugin
      ]}
      components={{
        a({node, className, children, ...props}) {
          return <Link
            {...props}
            className={styles.link}
          >
            {children}
          </Link>
        },
        p({node, className, children, ...props}) {
          return <p
            {...props}
            className={styles.p}
          >
            {children}
          </p>
        }
      }}
    />
  )
}

export default MarkdownReplacer
