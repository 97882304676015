import Types from './types';

const init = {
  list: null,
  unreadMessages: false,
};

export function notificationsReducer(state = init, action) {
  switch (action.type) {
    case Types.SET_NOTIFICATIONS: 
      return { ...state, list: action.payload.notifications, unreadMessages: action.payload.notifications.some(e => e.read === false) }
    default:
      return state;
  }
}