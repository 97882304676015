import Types from './types';

const init = {
  sockets: []
};

export function socketReducer(state = init, action) {
  switch (action.type) {
    case Types.SET_SOCKET: 
      return { ...state, sockets: [...state.sockets, action.payload.values] };
    default:
      return state;
  }
}