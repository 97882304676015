import { notificationInstance } from "../apiInitializator";

export class NotificationsRepositoryImpl {

  async get() {
    return notificationInstance.get('')
  }

  async read() {
    return notificationInstance.post('mark')
  }

  async mark(id) {
    return notificationInstance.post(`${id}/mark`)
  }
}