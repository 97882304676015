import { transformRequestOptions } from "../../utils/apiUtils";
import { productsInstance } from "../apiInitializator";

export class ProductsRepositoryImpl {

  async createProduct(product) {
    return productsInstance.post('', product)
  }

  async editProduct(id, product) {
    return productsInstance.post(`${id}`, product)
  }

  async deleteProduct(id) {
    return productsInstance.delete(`${id}`)
  }

  async updatePredictedPrice(id, price) {
    const obj = {}
    if (price) {
      obj.price = price
    }
    return productsInstance.post(`${id}/accept`, obj)
  }

  async setProductEnable(enabled, id) {
    const enabledQuery = enabled ? 'enable' : 'disable'
    return productsInstance.post(`${id}/${enabledQuery}`)
  }

  async getProductsList(requestParams) {
    return productsInstance.get(``, 
      {
        params: requestParams,
        paramsSerializer: params => transformRequestOptions(params)
      }
    )
  }

  async showProduct(id) {
    return productsInstance.get(`${id}`)
  }

  async showProductHistory(id, requestParams) {
    return productsInstance.get(`${id}/history`, 
      {
        params: requestParams,
        paramsSerializer: params => transformRequestOptions(params)
      }
    )
  }

  async fetchProduct(id) {
    return productsInstance.post(`${id}/fetch`)
  }

  async editProductPrice(id, price) {
    const [amount, currency] = price.split(' ')
    return productsInstance.post(`${id}/estimate`, {
      price: {
        amount,
        currency
      }
    })
  }

  async editProductSku(id, sku) {
    return productsInstance.post(`${id}/term`, {sku})
  }

  async describeProduct(id, obj) {
    return productsInstance.post(`${id}/describe`, obj)
  }

  async editProductTags(id, tags) {
    return productsInstance.post(`${id}/assign`, {
      tags
    })
  }

  async addProductCompetitor(id, competitor) {
    return productsInstance.post(`${id}/competitors`, competitor)
  }

  async deleteProductCompetitor(productId, competitorId) {
    return productsInstance.delete(`${productId}/competitors/${competitorId}`)
  }

  async getSuppliers(id) {
    return productsInstance.get(`${id}/suppliers`)
  }

  async addSupplier(id, obj) {
    return productsInstance.post(`${id}/suppliers`, obj)
  }

  async removeSupplier(productId, supplierId) {
    return productsInstance.delete(`${productId}/suppliers/${supplierId}`)
  }

  async preferSupplier(productId, supplierId) {
    return productsInstance.post(`${productId}/suppliers/${supplierId}/prefer`)
  }
}