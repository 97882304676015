import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Login from "./components/login/Login";
import {  Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import "./style.css";
import Main from "./components/main/Main";
import Enter from "./components/login/Enter";
import Registration from "./components/login/Registration";
import AmazonConnect from "./components/marketplaces/amazon/Connect";
import AmazonConfirm from "./components/marketplaces/amazon/Confirm";
import Spinner from "./components/spinner/Spinner";
import {useService} from './context/services.context'
import GlobalWrapper from "./components/other/GlobalWrapper";
import InteractiveSideBarWrapper from "./components/interactiveSidebar/interactiveSidebar";
import PaymentPage from "./components/payment/PaymentPage";
import Subscriptions from "./components/subscriptions/subscriptions";
import ChoosingPlan from "./components/subscriptions/choosing-plan";
import AddPaymentMethod from "./components/subscriptions/add-payment-method";
import store from "./store";
import { setSidebar } from "./store/sidebar/actions";

function App() {
  const {authService} = useService()
  const [history, ] = useState(
    createBrowserHistory({ basename: "/" })
  );
  const [isLoading, setLoading] = useState(true)

  const preloading = async () => {
    if (history.location.pathname === '/auth') {
      let localeItem = 'en'
      const locale = history.location.search.indexOf('locale');

      if (~locale) {
        const query = history.location.search.slice(locale).split(/&|=/)[1];
        localeItem = query
      }

      const idx = history.location.search.indexOf('token');

      if (~idx) {
        const query = history.location.search.slice(idx).split(/&|=/)[1];
        localStorage.setItem('i18nextLng', localeItem)
        await authService.signIn({access_token: query.toString()})
        history.push('/main')
      }
      setLoading(false)
      return
    }
    setLoading(false)
  }

  useEffect(() => {
    preloading()
  }, [history])

  return (
    <>
      <InteractiveSideBarWrapper
        children={{
          'payment-method': <PaymentPage className='mx-20' />
        }}
      />  
      {!isLoading ? <Router history={history}>
        <Layout
          style={{
            minHeight: "100vh",
            background:
              "linear-gradient(357.16deg, rgba(184, 205, 236, 0.4) 15.83%, rgba(195, 236, 184, 0) 99.25%)",
          }}
        >
          <Switch>
            <Route history={history} path="/auth/registration">
              <Login>
                <Registration history={history} />
              </Login>
            </Route>
            <Route history={history} path="/auth">
              <Login>
                <Enter />
              </Login>
            </Route>
            <Route history={history} path="/amazon/connect">
              <AmazonConnect />
            </Route>
            <Route history={history} path="/amazon/confirm">
              <AmazonConfirm />
            </Route>
            <Route history={history} path="/payment">
              <GlobalWrapper>
                <AddPaymentMethod />
              </GlobalWrapper>
            </Route>
            <Route history={history} path="/subscriptions/:id">
              <GlobalWrapper>
                <ChoosingPlan />
              </GlobalWrapper>
            </Route>
            <Route history={history} path="/subscriptions">
              <GlobalWrapper>
                <Subscriptions />
              </GlobalWrapper>
            </Route>
            <Route history={history} path="/">
              <GlobalWrapper>
                <Main />
              </GlobalWrapper>
            </Route>
            {/* <PrivateRoute history={history} path="/">
              <Main />
            </PrivateRoute> */}
          </Switch>
        </Layout>
      </Router> : <Spinner />}
    </>
  );
}

// function PrivateRoute({ children, ...rest }) {
//   let auth = localStorage.getItem("token");

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         auth ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "as.com/auth",
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

export default App;
