import Types from './types';

const init = {
  platforms: null,
  currencies: null,
  countries: null,
  tags: null,
  marketplaces: null,
};

export function dictionaryReducer(state = init, action) {
  switch (action.type) {
    case Types.SET_PLATFORMS: 
      return { ...state, platforms: action.payload.platforms };
    case Types.SET_CURRENCIES: 
      return { ...state, currencies: action.payload.currencies };
    case Types.SET_COUNTRIES: 
      return { ...state, countries: action.payload.countries };
    case Types.SET_TAGS: 
      return { ...state, tags: action.payload.tags };
    case Types.SET_MARKETPLACES: 
      return { ...state, marketplaces: action.payload.marketplaces };
    default:
      return state;
  }
}