export class UsersServiceImpl {
  constructor(usersRepository) {
    this.usersRepository = usersRepository
  }

  async createUser(user) {
    return this.usersRepository.createUser(user)
  }

  async deleteUser(id) {
    return this.usersRepository.deleteUser(id)

  }

  async getUsersList() {
    return this.usersRepository.getUsersList()

  }

  async editUserRoles(id, roles) {
    return this.usersRepository.editUserRoles(id, roles)

  }

  async editUserPassword(id, password) {
    return this.usersRepository.editUserPassword(id, password)
  }

  async setEnableUser(id, value) {
    return this.usersRepository.setEnableUser(id, value)
  }
}