export class RepricingServiceImpl {
  constructor(repricingRepository) {
    this.repricingRepository = repricingRepository
  }

  async get() {
    return this.repricingRepository.get()
  }

  async getById(id) {
    return this.repricingRepository.getById(id)
  }

  async createRule(obj) {
    return this.repricingRepository.createRule(obj)
  }

  async updateRule(id, obj) {
    return this.repricingRepository.updateRule(id, obj)
  }

  async deleteRule(id) {
    return this.repricingRepository.deleteRule(id)
  }
}