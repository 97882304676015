import { usersInstance } from "../apiInitializator";

export class UsersRepositoryImpl {

  async createUser(user) {
    return usersInstance.post('', user)
  }

  async deleteUser(id) {
    return usersInstance.delete(`${id}`)
  }

  async getUsersList() {
    return usersInstance.get(``)
  }

  async editUserRoles(id, roles) {
    return usersInstance.post(`${id}/roles`, roles)
  }

  async editUserPassword(id, obj) {
    return usersInstance.post(`${id}/protect`, obj)
  }

  async setEnableUser(id, value) {
    return usersInstance.post(`${id}/${value ? "enable" : "disable"}`)
  }
}