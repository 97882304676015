import { emptyInstance, paymentInstance } from "../apiInitializator";

export class PaymentRepositoryImpl {

  async getPlansList() {
    return paymentInstance.get('plans')
  }

  async getPublicPlansList() {
    return paymentInstance.get('plans', {
      headers: {
        Authorization: null
      }
    })
  }

  async showPlan(id) {
    return paymentInstance.get(`plans/${id}`)
  }
  
  async createPlan(values) {
    const {title, amount, currency, products, interval, trial, publicK, features, business} = values;

    return paymentInstance.post(`plans`, {
      title,
      features,
      business,
      cost: {
        amount,
        currency: currency.toLowerCase()
      },
      limits: {
        products
      },
      interval,
      trial,
      public: !!publicK,
    })
  }

  async setEnablePlan(id, value) {
    return paymentInstance.post(`plans/${id}/${value ? "enable" : "disable"}`)
  }

  async getPaymentMethods() {
    return paymentInstance.get(`methods/`)
  }

  async addPaymentMethod(data) {
    return paymentInstance.post(`methods`, data)
  }

  async switchPaymentMethod(id) {
    return paymentInstance.post(`methods/${id}/switch`, {})
  }

  async deletePaymentMethod(id) {
    return paymentInstance.delete(`methods/${id}`)
  }

  async addPromo(data) {
    return emptyInstance.post(`promo`, data)
  }

  async deletePromo(id) {
    return emptyInstance.delete(`promo/${id}`)
  }
}