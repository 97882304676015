import { action } from "..";
import Types from "./types";

const setPlatforms = (platforms) => {
  return action(Types.SET_PLATFORMS, {
    platforms
  });
}

const setCurrencies = (currencies) => {
  return action(Types.SET_CURRENCIES, {
    currencies
  });
}

const setCountries = (countries) => {
  return action(Types.SET_COUNTRIES, {
    countries
  });
}

const setTags= (tags) => {
  return action(Types.SET_TAGS, {
    tags
  });
}

const setMarketplacess = (marketplaces) => {
  return action(Types.SET_MARKETPLACES, {
    marketplaces
  });
}

export {
  setPlatforms,
  setCurrencies,
  setTags,
  setMarketplacess,
  setCountries,
}
