export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export function transformRequestOptions(params) {
  let options = '';
  for (const key in params) {
    if (!params[key]) {
      continue
    }
    if (typeof params[key] !== 'object' && (params[key] !== null && params[key] !== undefined)) {
      options += `${key}=${params[key]}&`;
    } else if (Array.isArray(params[key])) {
      params[key].forEach((e,i) => {
        options += `tags[${i}]=${e}&`
      })
    } else if (typeof params[key] === 'object' && (params[key] !== null && params[key] !== undefined) && params[key].length) {
        params[key].forEach((el) => {
            options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};

export function transformDataToKeyValue(data, value) {
  if (!data || data.length === 0) {
    return {}
  }
  const obj = {}
  data.map(e => {
    obj[e.key] = e.value
  })
  return value ? obj[value.toUpperCase()] : obj
}