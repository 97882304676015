import Types from './types';

const init = {
  me: null,
};

export function userReducer(state = init, action) {
  switch (action.type) {
    case Types.SET_USERS: 
      return { ...state, me: action.payload.user };
    default:
      return state;
  }
}