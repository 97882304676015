import { Carousel } from "antd";
import "./Login.scss";
import bgImage from "../../assets/bgImage.png";

const LoginContainer = ({ children }) => {

  return (
    <div className="login_wrapper">
      <div className="login_block">
        <div className="login_block_left">
          <img src={bgImage} alt="" />
          <Carousel>
            <div className="slider_item">
              <h2>Welcome aboard my friend </h2>
              <p>just a couple of clicks and we start</p>
            </div>
            <div className="slider_item">
              <h2>Welcome aboard my friend </h2>
              <p>just a couple of clicks and we start2</p>
            </div>
            <div className="slider_item">
              <h2>Welcome aboard my friend </h2>
              <p>just a couple of clicks and we start3</p>
            </div>
          </Carousel>
        </div>
        <div className="login_block_right">{children}</div>
      </div>
    </div>
  );
};

export default LoginContainer;
