const PlansIcon = ({active}) => {
  return active ? <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 6.33333C0 4.44772 0 3.50491 0.585786 2.91912C1.17157 2.33333 2.11438 2.33333 4 2.33333H20C21.8856 2.33333 22.8284 2.33333 23.4142 2.91912C24 3.50491 24 4.44772 24 6.33333V8C24 8.47141 24 8.70711 23.8536 8.85355C23.7071 9 23.4714 9 23 9H1C0.528596 9 0.292893 9 0.146447 8.85355C0 8.70711 0 8.4714 0 8V6.33333Z" fill="#00CCFF"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M0 21C0 22.8856 0 23.8284 0.585786 24.4142C1.17157 25 2.11438 25 4 25H20C21.8856 25 22.8284 25 23.4142 24.4142C24 23.8284 24 22.8856 24 21V12.6666C24 12.1952 24 11.9595 23.8536 11.8131C23.7071 11.6666 23.4714 11.6666 23 11.6666H1C0.528596 11.6666 0.292893 11.6666 0.146447 11.8131C0 11.9595 0 12.1952 0 12.6666V21ZM6 15.3333C6 14.8619 6 14.6262 6.14645 14.4798C6.29289 14.3333 6.5286 14.3333 7 14.3333H9.8C10.2714 14.3333 10.5071 14.3333 10.6536 14.4798C10.8 14.6262 10.8 14.8619 10.8 15.3333V16C10.8 16.4714 10.8 16.7071 10.6536 16.8535C10.5071 17 10.2714 17 9.8 17H7C6.5286 17 6.29289 17 6.14645 16.8535C6 16.7071 6 16.4714 6 16V15.3333ZM6.14645 19.8131C6 19.9595 6 20.1952 6 20.6666V21.3333C6 21.8047 6 22.0404 6.14645 22.1869C6.29289 22.3333 6.5286 22.3333 7 22.3333H9.8C10.2714 22.3333 10.5071 22.3333 10.6536 22.1869C10.8 22.0404 10.8 21.8047 10.8 21.3333V20.6666C10.8 20.1952 10.8 19.9595 10.6536 19.8131C10.5071 19.6666 10.2714 19.6666 9.8 19.6666H7C6.5286 19.6666 6.29289 19.6666 6.14645 19.8131ZM13.2002 15.3333C13.2002 14.8619 13.2002 14.6262 13.3466 14.4798C13.4931 14.3333 13.7288 14.3333 14.2002 14.3333H17.0002C17.4716 14.3333 17.7073 14.3333 17.8537 14.4798C18.0002 14.6262 18.0002 14.8619 18.0002 15.3333V16C18.0002 16.4714 18.0002 16.7071 17.8537 16.8535C17.7073 17 17.4716 17 17.0002 17H14.2002C13.7288 17 13.4931 17 13.3466 16.8535C13.2002 16.7071 13.2002 16.4714 13.2002 16V15.3333ZM13.3466 19.8131C13.2002 19.9595 13.2002 20.1952 13.2002 20.6666V21.3333C13.2002 21.8047 13.2002 22.0404 13.3466 22.1869C13.4931 22.3333 13.7288 22.3333 14.2002 22.3333H17.0002C17.4716 22.3333 17.7073 22.3333 17.8537 22.1869C18.0002 22.0404 18.0002 21.8047 18.0002 21.3333V20.6666C18.0002 20.1952 18.0002 19.9595 17.8537 19.8131C17.7073 19.6666 17.4716 19.6666 17.0002 19.6666H14.2002C13.7288 19.6666 13.4931 19.6666 13.3466 19.8131Z" fill="#00CCFF"/>
  <path d="M6 1L6 5" stroke="#00CCFF" strokeWidth="2" strokeLinecap="round"/>
  <path d="M18 1L18 5" stroke="#00CCFF" strokeWidth="2" strokeLinecap="round"/>
  </svg> : <svg style={{fill: 'none'}} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="3.82353" width="24" height="21.1765" rx="2" stroke="#C2CFE0"/>
    <path d="M6.3335 1L6.3335 3.82353" stroke="#C2CFE0" strokeLinecap="round"/>
    <path d="M25 8.05882L0.999999 8.05882" stroke="#C2CFE0" strokeLinecap="round"/>
    <path d="M19.6665 1L19.6665 3.82353" stroke="#C2CFE0" strokeLinecap="round"/>
    <rect x="6.3335" y="12.2941" width="5.33333" height="2.82353" rx="0.5" fill="#C2CFE0"/>
    <rect x="6.3335" y="17.9412" width="5.33333" height="2.82353" rx="0.5" fill="#C2CFE0"/>
    <rect x="14.3335" y="12.2941" width="5.33333" height="2.82353" rx="0.5" fill="#C2CFE0"/>
    <rect x="14.3335" y="17.9412" width="5.33333" height="2.82353" rx="0.5" fill="#C2CFE0"/>
  </svg>
}

export default PlansIcon