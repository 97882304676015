import React, { useEffect, useState } from "react";
import {useService} from '../../context/services.context'
import {
  Button,
  message,
} from "antd";
import { useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import { useHistory, useParams } from "react-router";
import PaymentPage from "../payment/PaymentPage";
import styles from './choosingPlan.module.scss'
import SubscriptionPlanBenefit from "./subscriptionPlanBenefit";
import { DateTime } from "luxon";
import { transformDataToKeyValue } from "../../utils/apiUtils";

const benefits = ['Up to 5 SKU', '1 user', 'AI based repricing', 'Unlimited competitors adding', 'Report emails']

const ChoosingPlan = () => {
  const history = useHistory()
  const { paymentService} = useService()
  let { id } = useParams();
  const { currencies } = useSelector(state => state.dictionary)
  const [isLoading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState()

  const getPlanList = async () => {
    try {
      const plans = await paymentService.getPlansList()
      const index = plans.findIndex(e => e.id === id)

      if (!~index) {
        history.goBack()
      }
      setSubscription(plans[index])
      setLoading(false)
    } catch(e) {
      message.error('Something went wrong!')
    }
  }

  const onSubmit = (values) => {
    console.log(values)
  }


  useEffect(() => {
    getPlanList()
  }, [])

  return !isLoading ? <div className='flex flex-col flex-grow items-center w-full'>
  <h2 className="text-3xl text-dark-1 font-medium mt-20 mb-8 capitalize-first">Enjoy your FREE Trial!</h2>
  <div className='flex flex-col flex-grow items-center justify-between w-full'>
    <div className={`${styles.content} flex p-5`}>
      <div>
        <h3 className='text-center text-xl mb-5'>Payment method</h3>
        <PaymentPage 
          onSubmit={onSubmit}
        />
      </div>
      {subscription && <div className={`${styles.info} mx-20 flex flex-col`}>
        <div className='flex-grow'>
          <h3 className='text-center text-xl mb-5'>Subscription checkout</h3>
          <p className='font-medium text-2xl mb-5'>{subscription.title} plan</p>
          <div className={`space-y-3 flex-grow`}>
            {benefits.map((benefit, index) => (<SubscriptionPlanBenefit 
              key={index}
            >
              <p className='text-m capitalize-first'>{benefit}</p>
            </SubscriptionPlanBenefit>))}
          </div>
          <div className={`${styles.badge} p-3 mt-5`}>
            <p className='text-s '>You do not need to pay for now. No-risk cancellation before the Trial ends</p>
          </div>
          <div className='flex w-full justify-between mt-5 text-m text-green-dark'>
            <p className='capitalize-first'>Due now</p>
            <p>$0.00</p>
          </div>
          <div className='flex w-full justify-between mt-1 text-m text-dark-2'>
            <p className='capitalize-first'>Starting {DateTime.utc().plus({month: 1}).toFormat("MMM d")}</p>
            <p>{`${transformDataToKeyValue(currencies, subscription.cost.currency)}${subscription.cost.amount}`}</p>
          </div>
        </div>
        <Button htmlType='submit' form='begateway-encrypted-form'>
          Start FREE Trial
        </Button>
      </div>}
    </div>
  </div>  
</div> : <Spinner />
}

export default ChoosingPlan

