const getAmazonPlatform = (url) => {

  let platform = url.slice(8)
  platform = platform.slice(0, platform.indexOf('/'))

  const allPlatforms = {
    "sellercentral.amazon.ca": "AMAZON_CA",
    "sellercentral.amazon.com": "AMAZON_US",
    "sellercentral.amazon.com.mx": "AMAZON_MX",
    "sellercentral.amazon.com.br": "AMAZON_BR",
    "sellercentral.amazon.es": "AMAZON_ES",
    "sellercentral.amazon.co.uk": "AMAZON_GB",
    "sellercentral.amazon.fr": "AMAZON_FR",
    "sellercentral.amazon.nl": "AMAZON_NL",
    "sellercentral.amazon.de": "AMAZON_DE",
    "sellercentral.amazon.it": "AMAZON_IT",
    "sellercentral.amazon.se": "AMAZON_SE",
    "sellercentral.amazon.pl": "AMAZON_PL",
    "sellercentral.amazon.eg": "AMAZON_EG",
    "sellercentral.amazon.com.tr": "AMAZON_TR",
    "sellercentral.amazon.sa": "AMAZON_SA",
    "sellercentral.amazon.ae": "AMAZON_AE",
    "sellercentral.amazon.in": "AMAZON_IN",
    "sellercentral.amazon.sg": "AMAZON_SG",
    "sellercentral.amazon.com.au": "AMAZON_AU",
    "sellercentral.amazon.co.jp": "AMAZON_JP",
  }

  return allPlatforms[platform]
}

export default getAmazonPlatform

