import store from "../../store"
import { setNotifications } from "../../store/notifications/actions"

export class NotificationsServiceImpl {
  constructor(notificationsRepository) {
    this.notificationsRepository = notificationsRepository
  }

  async get() {
    return this.notificationsRepository.get()
  }

  async read() {
    return this.notificationsRepository.read()

  }

  async mark(id) {
    return this.notificationsRepository.mark(id)
  }

  async load() {
    const notifications = await this.get()
    store.dispatch(setNotifications(notifications))
    return notifications
  }
}