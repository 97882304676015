import { Redirect, useHistory } from "react-router-dom";
import "./Login.scss";
import LoginContainer from "./LoginContainer";

const Login = ({ children }) => {
  const history = useHistory();
  const auth = localStorage.getItem("token");

  return (<>
    {auth && (
    <Redirect
      to={{
        pathname: "/",
        state: { from: history.location },
      }}
    />
    )}
    <LoginContainer>
      {children}
    </LoginContainer>
  </>
  );
};

export default Login;
