import { repricingInstance } from "../apiInitializator";

export class RepricingRepositoryImpl {

  async get() {
    return repricingInstance.get()
  }

  async getById(id) {
    return repricingInstance.get(id)
  }

  async createRule(obj) {
    return repricingInstance.post('', obj)
  }

  async updateRule(id, obj) {
    return repricingInstance.put(id, obj)
  }

  async deleteRule(id) {
    return repricingInstance.delete(id)
  }

}