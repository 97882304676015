import store from "../../store";
import { setUser } from "../../store/users/actions";
import { tokenKey, userTokenKey } from "../apiInitializator";

export class AuthServiceImpl {
  constructor(authRepository) {
    this.authRepository = authRepository
  }

  async getAccessToken(fields) {
    return this.authRepository.getAccessToken(fields)
  }

  async getAccessTokenUnderUser(id) {
    return this.authRepository.getAccessTokenUnderUser(id)
  }

  async signIn(token) {
    localStorage.setItem(tokenKey, token.access_token);
    if (localStorage.getItem('pastPath')) {
      window.location.href = localStorage.getItem('pastPath')
    }
    return
  }

  async signInUnderUser(token) {
    localStorage.setItem(userTokenKey, token.access_token);
    window.location.pathname = '/'
  }

  async getUserInfo() {
    const user = await this.authRepository.getUserInfo()
    store.dispatch(setUser(user))
    return user
  }
}