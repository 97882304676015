import { accountsInstance, brandsInstance, categoriesInstance } from "../apiInitializator";
import { transformRequestOptions } from "../../utils/apiUtils";

export class BrandsRepositoryImpl {
  async getAccounts() {
    return accountsInstance.get('')
  }

  async watchCategory(accountId, category) {
    return accountsInstance.post(`${accountId}/watch`, {category});
  }

  async unwatchCategory(accountId, category) {
    return accountsInstance.post(`${accountId}/unwatch`, {category});
  }

  async import(account, discount, tags) {
    const data = {}
    if (discount) {
      data.discount = discount;
    }
    if (tags) {
      data.tags = tags;
    }
    return accountsInstance.post(`${account}/import`, data)
  }

  async getCategoriesList(platform) {
    return categoriesInstance.get('', {
      params: {platform},
      paramsSerializer: params => transformRequestOptions(params)
    })
  }

  async getCategoriesChildrenList(platform, parent) {
    return categoriesInstance.get('', {
      params: {platform, parent},
      paramsSerializer: params => transformRequestOptions(params)
    })
  }

  async getBrandsList(account, category, min, max) {
    const params = {}
    if (min) {
      params.min = min
    }
    if (max) {
      params.max = max
    }
    if (account) {
      params.account = account
    }
    if (category) {
      params.category = category
    }
    return brandsInstance.get('', {
      params,
      paramsSerializer: params => transformRequestOptions(params)
    })
  }
}