import axios from "axios";
import { transformRequestOptions } from "../../utils/apiUtils";
import { createAxiosInstance, errorHandler, reportsInstance, requestHandler } from "../apiInitializator";

const emptyReportInstance = createAxiosInstance('reports/')

emptyReportInstance.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error)
)
emptyReportInstance.interceptors.response.use(
  response => (response),
  error => errorHandler(error)
)
export class ReportsRepositoryImpl {

  async getCompetitorsReports(requestParams) {
    return reportsInstance.get('competitors', {
      params: requestParams,
      headers: {
        "Accept": "application/json",
      },
      paramsSerializer: params => transformRequestOptions(params)
    })
  }
  
  async downloadCompetitorsReports(requestParams) {
    return emptyReportInstance.get('competitors', {
      params: requestParams,
      headers: {
        "Accept": "text/csv",
      },
      paramsSerializer: params => transformRequestOptions(params)
    })
  }
}