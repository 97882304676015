const UsersIcon = ({active}) => {
  return active ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 4.22876 0 2.34315 1.17157 1.17157C2.34315 0 4.22876 0 8 0H16C19.7712 0 21.6569 0 22.8284 1.17157C24 2.34315 24 4.22876 24 8V16C24 19.7712 24 21.6569 22.8284 22.8284C21.6569 24 19.7712 24 16 24H8C4.22876 24 2.34315 24 1.17157 22.8284C0 21.6569 0 19.7712 0 16V8ZM7.00796 17.4945C8.44005 16.3956 10.1947 15.8 11.9998 15.8C13.8049 15.8 15.5596 16.3956 16.9916 17.4945C18.4237 18.5934 19.4532 20.1341 19.9204 21.8777C20.0633 22.4112 19.7468 22.9595 19.2133 23.1024C18.6798 23.2454 18.1315 22.9288 17.9885 22.3953C17.6353 21.077 16.8569 19.9121 15.7741 19.0812C14.6913 18.2504 13.3646 17.8 11.9998 17.8C10.635 17.8 9.30828 18.2504 8.22548 19.0812C7.14269 19.9121 6.36431 21.077 6.01107 22.3953C5.86812 22.9288 5.31979 23.2454 4.78632 23.1024C4.25285 22.9595 3.93627 22.4111 4.07921 21.8777C4.54641 20.1341 5.57588 18.5934 7.00796 17.4945ZM9.3999 8.4C9.3999 6.96406 10.564 5.8 11.9999 5.8C13.4358 5.8 14.5999 6.96406 14.5999 8.4C14.5999 9.83594 13.4358 11 11.9999 11C10.564 11 9.3999 9.83594 9.3999 8.4ZM11.9999 3.8C9.45939 3.8 7.3999 5.85949 7.3999 8.4C7.3999 10.9405 9.45939 13 11.9999 13C14.5404 13 16.5999 10.9405 16.5999 8.4C16.5999 5.85949 14.5404 3.8 11.9999 3.8Z" fill="#00CCFF"/>
  </svg> : <svg style={{fill: 'none'}} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_0_291" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_0_291)">
    <path d="M19.1199 23.3095C18.8604 21.8575 17.9817 20.5343 16.6476 19.5865C15.3136 18.6386 13.6151 18.1308 11.8686 18.1576C10.1221 18.1843 8.44672 18.7439 7.15464 19.7319C5.86255 20.72 5.04185 22.0693 4.84546 23.5283" stroke="#C2CFE0"/>
    <ellipse cx="11.9999" cy="9.68347" rx="3.6" ry="3.6313" stroke="#C2CFE0" strokeLinecap="round"/>
    <rect x="0.5" y="0.5" width="23" height="23.2087" rx="3.5" stroke="#C2CFE0"/>
    </g>
  </svg>
}

export default UsersIcon