import React from "react";
import "./Spinner.scss";
const Spinner = ({
  mini = false,
  className = ''
}) => {
  return (
    <div className={`${mini ? 'mini' : 'fallback'}-spinner ${className}`}>
      <div className="loading component-loader">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};
export default Spinner;
