import axios from "axios";
import { isEmpty } from "../utils/apiUtils";

export const baseApiUrl = '/api/'
export const tokenKey = 'token'
export const userTokenKey = 'user-token'
export const redirectNoAuthPath = 'https://pricex.ai/'

export const requestHandler = (request) => {
  const token = tokenKey && (localStorage.getItem(userTokenKey) || localStorage.getItem(tokenKey))
  if (token) {
    request.headers.Authorization = 'Bearer ' + token
    request.headers["Accept-Language"] = "ru-RU"
  }
  return request;
};

export const responseHandler = (response) => {
  const headerToken = response.headers.authorization
  if (tokenKey && headerToken) {
    localStorage.setItem(tokenKey, headerToken)
  }
  return isEmpty(response.data) ? response : response.data;
};

export const errorHandler = async (error) => {
  if (error.response && error.response.status === 401 && localStorage.getItem(userTokenKey)) {
    window.location.href = window.location.href
    localStorage.removeItem(userTokenKey)
    return
  }else if (error.response && error.response.status === 401) {
    localStorage.clear()
    localStorage.setItem('pastPath', window.location.href)
    if (!window.location.pathname.includes('auth')) {
      window.location.href = redirectNoAuthPath
    }
    
    return Promise.reject({detail: {
      code: 'UNAUTHORIZED'
    }});
  } else if (error.response && error.response.status >= 400 && error.response.status < 500) {
    return Promise.reject(error.response.data);
  } else if (axios.isCancel(error)) {
    return Promise.reject({detail: {
      code: 'CLIENT_CANCELED_REQUEST'
    }});
  } else {
    return Promise.reject({detail: {
      code: 'UNKNOWN'
    }});
  }
}

export function createAxiosInstance(url) {
  return axios.create({
    baseURL: `${baseApiUrl ? `${baseApiUrl}` : ''}${url}`,
  });
}

const emptyInstance = createAxiosInstance('');
const authInstance = createAxiosInstance('auth/');
const dictionaryInstance = createAxiosInstance('dictionary/');
const usersInstance = createAxiosInstance('users/')
const productsInstance = createAxiosInstance('products/')
const statisticInstance = createAxiosInstance('statistic/')
const reportsInstance = createAxiosInstance('reports/')
const notificationInstance = createAxiosInstance('notifications/')
const platformsInstance = createAxiosInstance('platforms/')
const paymentInstance = createAxiosInstance('payment/')
const repricingInstance = createAxiosInstance('rules/')
const accountsInstance = createAxiosInstance('accounts/')
const categoriesInstance = createAxiosInstance('categories/')
const brandsInstance = createAxiosInstance('brands/')

const instances = [
  emptyInstance,
  authInstance,
  dictionaryInstance,
  usersInstance,
  accountsInstance,
  productsInstance,
  platformsInstance,
  statisticInstance,
  reportsInstance,
  notificationInstance,
  paymentInstance,
  repricingInstance,
  categoriesInstance,
  brandsInstance
];

instances.forEach(instance => {
  instance.interceptors.request.use(
    request => requestHandler(request),
    error => errorHandler(error)
  );
  instance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
  );
})

export {
  emptyInstance,
  authInstance,
  dictionaryInstance,
  usersInstance,
  accountsInstance,
  productsInstance,
  platformsInstance,
  statisticInstance,
  reportsInstance,
  notificationInstance,
  paymentInstance,
  repricingInstance,
  categoriesInstance,
  brandsInstance
};