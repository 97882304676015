import React, { useEffect, useState } from "react";
import {useService} from '../../context/services.context'
import {
  message,
} from "antd";
import SubscriptionPlan from "./subscriptionPlan";
import { useSelector } from "react-redux";
import { transformDataToKeyValue } from "../../utils/apiUtils";
import Spinner from "../spinner/Spinner";

const benefits = [
  ['Up to 5 SKU', '1 user', 'AI based repricing', 'Unlimited competitors adding', 'Report emails'],
  ['Up to 100 SKU', '3 users', 'AI based repricing', 'Unlimited competitors adding', 'Report emails'],
  ['Up to 500 SKU', '5 users', 'AI based repricing', 'Unlimited competitors adding', 'Report emails', 'Market share calculation', 'Advanced analytics and reporting', 'Custom reports creation', 'Competitors prices history']
]

const Subscriptions = () => {
  const { paymentService} = useService()
  const { currencies } = useSelector(state => state.dictionary)
  const [isLoading, setLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])

  const getPlanList = async () => {
    try {
      const plans = await paymentService.getPlansList()
      setSubscriptions(plans)
      setLoading(false)
    } catch(e) {
      message.error('Something went wrong!')
    }
  }

  useEffect(() => {
    getPlanList()
  }, [])

  return !isLoading ? <div className="flex flex-col flex-grow items-center w-full">
    <h2 className="text-3xl text-dark-1 font-medium mt-20 mb-8">Choose Subscription Plan</h2>
    <div className='flex flex-col flex-grow items-center justify-between w-full'>
      {!!subscriptions.length && <div className='mt-8 flex flex-wrap justify-center gap-8'>
        {subscriptions.map((e, i) => (<SubscriptionPlan
          key={e.id}
          title={e.title}
          subtitle={`${transformDataToKeyValue(currencies, e.cost.currency)}${e.cost.amount}`}
          description={`${e.trial} trial days`}
          btnText='Choose'
          benefits={benefits[i]}
          link={`/subscriptions/${e.id}`}
        />))}  
      </div>}
    </div>
  </div> : <Spinner />
}

export default Subscriptions