import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SubscriptionPlanBenefit from "./subscriptionPlanBenefit";
import styles from './subscriptions.module.scss'



const SubscriptionPlan = ({
  className,
  title,
  subtitle,
  description,
  benefits,
  btnText,
  link,
  isPopular = false,
  ...rest
}) => {

  return (
    <div className={`${className} ${styles.plan} flex flex-col items-center`} {...rest}>
      {isPopular&& <div className={styles.popular}>
        <p className='text-s text-accent-1 font-medium capitalize-first w-full text-center'>Most popular plan</p>
      </div>} 
      <div className={`${styles['header']} flex flex-col items-center justify-center`}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.subtitle}>{subtitle}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={`${styles.content} space-y-3 flex-grow`}>
        {benefits.map((benefit, index) => (<SubscriptionPlanBenefit 
          key={index}
        >
          <p className='text-dark-3 text-m capitalize-first'>{benefit}</p>
        </SubscriptionPlanBenefit>))}
      </div>
      <div className={styles['button-wrapper']}>
        <Button
          className={styles.button}
          type='primary'
        >
          <Link to={link}><p className='capitalize-first font-medium text-m'>{btnText}</p></Link>
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
