import React, { createContext, useContext } from 'react';

const ServicesContext = createContext(null);

const ServicesProvider = ({
  children,
  services
}) => {
  return (
    <ServicesContext.Provider
      value={services}
    >
      {children}
    </ServicesContext.Provider>
  )
}

const useService = () => useContext(ServicesContext);

export { ServicesProvider, useService }