import store from "../../store"
import { setCountries, setCurrencies, setMarketplacess, setPlatforms, setTags } from "../../store/dictionary/actions"

export class DictionaryServiceImpl {
  constructor(dictionaryRepository) {
    this.dictionaryRepository = dictionaryRepository
  }

  async getPlatforms() {
    const platforms = await this.dictionaryRepository.getPlatforms()
    store.dispatch(setPlatforms(platforms))
    return platforms
  }

  async getCountries() {
    const countries = await this.dictionaryRepository.getCountries()
    store.dispatch(setCountries(countries))
    return countries
  }

  async getCurrencies() {
    const currencies = await this.dictionaryRepository.getCurrencies()
    store.dispatch(setCurrencies(currencies))
    return currencies
  }

  async getTags() {
    const tags = await this.dictionaryRepository.getTags()
    store.dispatch(setTags(tags))
    return tags
  }

  async getMarketplaces() {
    const marketplaces = await this.dictionaryRepository.getMarketplaces()
    store.dispatch(setMarketplacess(marketplaces))
    return marketplaces
  }

  async load() {
    await Promise.all([
      this.getPlatforms(),
      this.getCurrencies(),
      this.getTags(),
      this.getMarketplaces(),
      this.getCountries(),
    ])
  }
}