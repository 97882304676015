import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import { Redirect, useHistory } from "react-router";
import PaymentPage from "../payment/PaymentPage";
import styles from './choosingPlan.module.scss'
import {Checkbox, message as AntMessage, Result, Button} from 'antd'
import store from "../../store";
import { setUser } from "../../store/users/actions";
import {useService} from '../../context/services.context'
import Spinner2 from "../spinner/Spinner2";
import MarkDownReplacer from '../markdown/MarkDownReplacer'
import { useTranslation } from 'react-i18next';
import { userTokenKey } from "../../api/apiInitializator";

const AddPaymentMethod = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {authService} = useService()
  const { sockets } = useSelector(state => state.socket)
  const [isLoading, setLoading] = useState(true)
  const [isProcessing, setProcessing] = useState(null)

  const [me, setMe] = useState()

  const socketWait = useRef(true)

  const loadUser = async () => {
    const user = await authService.getUserInfo()
    store.dispatch(setUser(user))
    setMe(user)
    setLoading(false)
    return user
  }

  const afterPayment = async () => {
    const {type, payload} = sockets.pop()
    if (type && type === 'payment_socket_success') {
      //AntMessage.success(message)
      setProcessing(payload.message)
      await loadUser()
      setTimeout(() => {
        history.push('/settings')
      }, 2000)
      socketWait.current = false
    }
    if (type && type === 'payment_socket_failure') {
      AntMessage.error(payload.message || t('messages.somethingWrong'))
      socketWait.current = false
      setProcessing(null)
      setTimeout(() => socketWait.current = true, 3000)
    }
    if (type && type === 'payment_socket_pending') {
      window.location.href = payload.redirect
    }
    socketWait.current = false
  }

  const isUserHaveMethod = useMemo(() => {
    return me && me.method !== '00000000-0000-0000-0000-000000000000'
  }, [me])

  const after3DS = async () => {
    const user = await loadUser()
    const params = new URLSearchParams(history.location.search)
    const status = params.get('status')
    if (status === 'processing') {
      setProcessing(true)
      if (user.method !== '00000000-0000-0000-0000-000000000000') {
        socketWait.current = false
        setProcessing('Success!')
        setTimeout(() => {
          history.push('/settings')
        }, 2000)
      }
    }
  }

  useEffect(() => {
    after3DS()
  }, [])

  useEffect(() => {
    if (socketWait.current && !!sockets.length) {
      afterPayment()
    }
  }, [sockets])

  const onSubmit = () => {
    socketWait.current = true
    setProcessing(true)
  }

  const logoutUnderUser = async () => {
    window.location.pathname = '/users'
    localStorage.removeItem(userTokenKey)
  }

  return isProcessing === null ? (!isLoading ? <>
    <div className='flex flex-col flex-grow items-center w-full'>
    {localStorage.getItem(userTokenKey) && <div className='login-by-user-notification'>
          <p>You login by <strong>{me.name}</strong></p>
          <Button danger onClick={logoutUnderUser}>{t('menu.logout')}</Button>
      </div>}
    {!isUserHaveMethod && (
      <h2 className="text-3xl text-dark-1 font-medium mt-10 capitalize-first">{t('payment.completeReg')}</h2>
    )}
    <div className='flex flex-col flex-grow items-center justify-between w-full mt-8'>
      <div className={`${styles.content} flex p-5`}>
        <div>
          <h3 className='text-center text-xl mb-5 font-medium'>{t('payment.addBillingAdress')}</h3>
          <PaymentPage 
            isShowPaymentText
            onSubmit={onSubmit}
            width={400}
            children={<Checkbox required onChange={() => {}}>
              <MarkDownReplacer markdown={t('payment.agree')} />
            </Checkbox>}
            buttonText={!isUserHaveMethod ? t('payment.startFreeTrial') : t('payment.addMethod_')}
          />
        </div>
      </div>
    </div>  
  </div></> : <Spinner />) : (isProcessing === true ? <Spinner2 speed={3} customText={t('payment.processing')}/> : 
  <div className='flex flex-col flex-grow items-center w-full justify-center'>
    <Result
      status="success"
      title={isProcessing}
      // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      // extra={[
      //   <Button type="primary" key="console">
      //     Go Console
      //   </Button>,
      //   <Button key="buy">Buy Again</Button>,
      // ]}
    />
  </div>
  )
}

export default AddPaymentMethod

